import Cube from "./Cube";
import Loading from "./Loading";

export default function Cubes({ weekData }) {

    const calculateTemperatureAverage = (data) => {
        let totalTemp = 0;
        data.forEach((reading) => {
            totalTemp += reading.temperature;
        });
        return totalTemp / data.length;
    };

    const calculateHumidityAverage = (data) => {
        let totalHumidity = 0;
        data.forEach((reading) => {
            totalHumidity += reading.humidity;
        });
        return totalHumidity / data.length;
    };

    const findEarliestRecord = (data) => {
        if (data && data.length > 0) {
            let earliestRecord = data[0];
            data.forEach((reading) => {
                if (new Date(reading.createdAt) < new Date(earliestRecord.createdAt)) {
                    earliestRecord = reading;
                }
            });
            return earliestRecord.createdAt;
        }
        return null;
    };
    
    if (!weekData) {
        return (
            <div className="bg-base-300 flex justify-center items-center p-8 rounded-lg shadow-lg">
                <Loading />
            </div>
        );
    }
    return (
        <div className="bg-base-300 p-8 rounded-lg shadow-lg">
            {!weekData ? <Loading /> :  (
                <Cube
                    title={"Week Averages"}
                    temperature={calculateTemperatureAverage(weekData)}
                    humidity={calculateHumidityAverage(weekData)}
                    date={findEarliestRecord(weekData)}
                    time={"First record from"}
                />
            )}
        </div>
    );
}