import Cube from "./Cube";
import Loading from "./Loading";

export default function Current({ data }) {
    if (!data) {
        return <Loading />;
    }
    return (
        <div className="bg-base-300 p-8 rounded-lg shadow-lg">
            <Cube
                title={"Current Readings"}
                temperature={data.temperature}
                humidity={data.humidity}
                date={data.createdAt}
                time={"Last updated at "}
            />
        </div>
    );
}