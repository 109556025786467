import { Line } from 'react-chartjs-2';
import Loading from "./Loading"
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

export default function Graph({ data, title }) {

    const chartData = {
        labels: data.weekDays,
        datasets: [
            {
                label: 'Temperature (°C)',
                data: data.avgTemperatures,
                fill: false,
                // borderColor: 'hsl(346.8, 77.2%, 49.8%)',
                borderColor: 'hsl(240, 4.8%, 95.9%)',
                tension: 0.1
            },
            {
                label: 'Humidity (%)',
                data: data.avgHumidity,
                fill: false,
                borderColor: 'hsl(346.8, 77.2%, 49.8%)',
                tension: 0.1
            }
        ]
    };

    const options = {
        scales: {
            x: {
                display: false, 
            },
            y: {
                beginAtZero: false,
                ticks: {
                    display: false 
                },
                grid: {
                    display: false 
                }
            }
        }
    };

    return (
        <div className='bg-base-300 p-8 rounded-lg shadow-lg'>
            <h2 className="text-2xl text-center font-semibold text-white/85 mb-2">{title}</h2>
            {data ? <Line data={chartData} options={options} /> : <Loading />}
        </div>
    );
}

