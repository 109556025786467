export default function Cube({ title, temperature, humidity, date, time}) {

    const formatDate = (date) => {
        const options = { month: 'short', day: 'numeric' };
        const formattedDate = new Date(date).toLocaleDateString('en-US', options);

        const day = new Date(date).getDate();
        const suffix = ['th', 'st', 'nd', 'rd'][(day % 10 > 3 || (day >= 11 && day <= 13)) ? 0 : day % 10];

        return `${formattedDate.replace(/\d+/, day + suffix)}`;
    };


    return (
        <div className="grid gap-4">
            <h2 className="text-2xl text-center font-semibold text-white/85">{title}</h2>
            <div className="flex items-end justify-center">
                <span className="text-8xl font-bold text-primary">
                    {temperature ? `${temperature.toFixed(1)}°` : '...'}
                </span>
                <span className="text-4xl font-bold text-secondary">C</span>
            </div>
            <div className="flex items-end justify-center gap-2">
                <span className="text-4xl font-bold text-white/85">
                    {humidity ? `${humidity.toFixed(1)}%` : '...'}
                </span>
                <span className="text-2xl font-semibold text-[hsl(346.8,77.2%,49.8%)]">Humidity</span>
            </div>
            <div className="flex items-center justify-center text-white/60 text-sm">
                {time} {date !== null ? new Date(date).toLocaleTimeString() : '...'} 
            </div>
        </div>
    );
}