import { useEffect, useState } from 'react';

import Current from '../components/Current';
import Averages from '../components/Averages';
import Graph from '../components/Graph';
import Loading from '../components/Loading';

export default function Dashboard() {
    const [weekData, setWeekData] = useState([]);
    const [dailyAverages, setDailyAverages] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchWeekReadings = async () => {
            try {
                const response = await fetch('http://localhost:3001/readings');
                if (!response.ok) {
                    throw new Error('Failed to fetch weekly readings');
                }
                const data = await response.json();
                setWeekData(data);
                setDailyAverages(calculateDailyAverages(data));
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchWeekReadings();
    }, []);

    const calculateDailyAverages = (data) => {
        const dailyData = {};
        const today = new Date();
        
        // Get all days of the week
        const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const todayIndex = today.getDay();
        
        // Reorder weekDays to start with last Sunday (or today if it's Sunday)
        const orderedWeekDays = [
            ...weekDays.slice(todayIndex === 0 ? 0 : todayIndex),
            ...weekDays.slice(0, todayIndex === 0 ? 0 : todayIndex)
        ];

        // Initialize data for each day
        orderedWeekDays.forEach(day => {
            dailyData[day] = { tempSum: 0, humiditySum: 0, count: 0 };
        });

        // Process the readings
        data.forEach(({ createdAt, temperature, humidity }) => {
            const readingDate = new Date(createdAt);
            const dayIndex = readingDate.getDay();
            const dayLabel = weekDays[dayIndex];
            
            if (dailyData[dayLabel]) {
                dailyData[dayLabel].tempSum += temperature;
                dailyData[dayLabel].humiditySum += humidity;
                dailyData[dayLabel].count += 1;
            }
        });

        // Calculate averages for each day
        const avgTemperatures = orderedWeekDays.map(day => 
            dailyData[day].count ? parseFloat((dailyData[day].tempSum / dailyData[day].count).toFixed(1)) : null
        );

        const avgHumidity = orderedWeekDays.map(day => 
            dailyData[day].count ? parseFloat((dailyData[day].humiditySum / dailyData[day].count).toFixed(1)) : null
        );

        return {
            weekDays: orderedWeekDays,
            avgTemperatures,
            avgHumidity
        };
    };

    return (
        <div className='flex bg-base-100 items-center justify-center w-screen min-h-screen p-4'>
            {!loading ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <Current data={weekData[0]} />
                    <Averages weekData={weekData} />
                    <Graph title="Daily Graph" data={weekData} />
                    <Graph title="Week Graph" data={calculateDailyAverages(weekData)} />
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
}